<template>

  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>TEXKOM - Управление процессами</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert v-model="error" type="error">Неверный логин или пароль</v-alert>
            <v-form @keyup.native.enter="submit">
              <v-text-field prepend-icon="mdi-account" v-model="login" name="login" label="Логин" type="text"></v-text-field>
              <v-text-field prepend-icon="mdi-lock" v-model="password" name="password" label="Пароль" id="password" type="password"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" @click="submit" color="primary">Войти</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>

</template>

<script>
  export default {
    data () {
      return {
        login: '',
        password: '',
        error: false
      }
    },
    computed: {
      valid () {
        return this.login && this.password
      }
    },
    methods: {
      submit () {
        this.$axios.post('login', {login: this.login, password: this.password})
          .then(r => this.loginSuccess(r))
          .catch(() => this.loginFail())
      },
      loginSuccess (r) {
        if (!r.data.token) {
          this.loginFail()
          return
        }
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.setItem('token', r.data.token)
        localStorage.setItem('user', JSON.stringify(r.data.user))
        this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + r.data.token
        switch (r.data.user.role.slug) {
          case 'master':
            this.$router.push('/statement-list')
          break
          case 'keeper':
            this.$router.push('/statement-list')
          break
          case 'admin':
            this.$router.push('/statement-list')
          break
        }
      },
      loginFail () {
        localStorage.removeItem('token')
        this.$axios.defaults.headers.common['Authorization'] = null
        this.login = ''
        this.password = ''
        this.error = true
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
