import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import VueHtmlToPaper from 'vue-html-to-paper'

Vue.use(VueHtmlToPaper, {
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
  ]
})
Vue.config.productionTip = false

Vue.mixin({
  computed: {
    userIsMaster () { return this.getuser().role.slug === 'master' },
    userIsKeeper () { return this.getuser().role.slug === 'keeper' },
    userIsAdmin () { return this.getuser().role.slug === 'admin' },
  },
  methods: {
    isAuthorized () {
      return localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined
    },
    getuser () {
      if (localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'))
        return Object.keys(user).length > 0 ? user : false
      } else {
        return false
      }
    },
    getStatusColor (status) {
      if (status === undefined) {
        return 'grey'
      }
      switch (status.slug) {
        case 'draft': 
          return 'blue-grey'
        case 'verify': 
          return 'orange'
        case 'work': 
          return 'blue'
        case 'done': 
          return 'green'
        case 'closed': 
          return 'blue-grey lighten-3'
        default:
          return 'grey'
      }
    },
    statusIsDraft (status) { return status.slug === 'draft'},
    statusIsVerify (status) { return status.slug === 'verify'},
    statusIsWork (status) { return status.slug === 'work'},
    statusIsDone (status) { return status.slug === 'done'},
    statusIsClosed (status) { return status.slug === 'closed'},
    statusIsCloseDone (status) { return status.slug === 'closed' || status.slug === 'done'},
    statusGetDraft (statuses) { return statuses.find(s => s.slug === 'draft') },
    statusGetVerify (statuses) { return statuses.find(s => s.slug === 'verify') },
    statusGetWork (statuses) { return statuses.find(s => s.slug === 'work') },
    statusGetDone (statuses) { return statuses.find(s => s.slug === 'done') },
    statusGetClosed (statuses) { return statuses.find(s => s.slug === 'closed') },
    forceSWupdate () {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (let registration of registrations) {
            registration.update()
          }
        })
      }
    },
    fixed2 (num) {
      return typeof num === 'number' ? num.toFixed(2) : num
    },
    endPrice (item) {
      if (item.pivot === undefined || item.pivot.price === undefined) {
        return item.price
      } else {
        return item.pivot.price
      }
    }
  }
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
