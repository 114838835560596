<template>
 <v-app id="inspire">
   <v-app-bar app dark color="primary" v-if="isAuthorized()">
      <!-- <v-toolbar-title>{{appTitle}}</v-toolbar-title> -->
      <v-btn
       text
       v-for="route in userRoutes()"
       :key="route.name"
       :to="{name: route.path}"
       class="mr-2 px-2"
       >{{route.name}}</v-btn>

      <v-btn 
        icon 
        color="white" 
        @click="refresh"
        v-if="isMobileDevice"
      >
        <v-icon>mdi-cached</v-icon>
      </v-btn>

      <div class="flex-grow-1"></div>

      <v-menu left bottom>
       <template v-slot:activator="{ on }">
         <v-btn text v-on="on" v-if="getuser()">
            <v-icon medium class="pr-2">mdi-account</v-icon>
            {{getuser().name}} ({{getuser().role.name}})
           <v-icon>mdi-menu-down</v-icon>
         </v-btn>
       </template>

       <v-list>
         <v-list-item to="/logout">
           <v-list-item-title>Выйти из системы</v-list-item-title>
         </v-list-item>
       </v-list>
     </v-menu>
   </v-app-bar>

   <v-content>
    <v-container class="fill-width" fluid>
      <router-view/>
    </v-container>
   </v-content>

   <v-snackbar v-model="updateExists">
     Доступна новая версия приложения
     <v-btn
       color="pink"
       text
       @click="updateExists = false; refreshApp"
     >
       Обновить
     </v-btn>
   </v-snackbar>

   <v-footer dark app color="primary" class="overline">
     <span class="pr-1">{{appTitle}} &copy; {{currentYear}}</span><span v-if="appVersion">(Версия {{appVersion}}) new</span>
   </v-footer>
 </v-app>
</template>

<script>
  export default {
    data: () => ({
      appTitle: 'TEXKOM - Управление процессами',
      appVersion: false,
      refreshing: false,
      registration: null,
      updateExists: false,
      userRoutesData: [
        {
          user: ['master', 'keeper', 'admin'],
          name: 'Ведомости',
          path: 'statement-list',
        },
        {
          user: ['master', 'admin'],
          name: 'Оборудование',
          path: 'equipment-list',
        },
        {
          user: ['keeper', 'admin'],
          name: 'Склад',
          path: 'part-list',
        },
        {
          user: ['admin'],
          name: 'Пользователи',
          path: 'user-list',
        },
      ],
    }),

    created () {
      // Listen for swUpdated event and display refresh snackbar as required.
      if (document) {
        document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
      }
      // Refresh all open app tabs when a new service worker is installed.
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        })
      }
      this.$axios.get('version')
        .then(r => {
          if (r.data.result) {
            this.appVersion = r.data.version
          }
        })
    },

    computed: {
      currentYear () {
        return new Date().getFullYear()
      },
      isMobileDevice () {
        return navigator.userAgent.search(/ipad|android/i) !== -1
      },
    },

    methods: {
      userRoutes () {
        return this.userRoutesData.filter(d => d.user.includes(this.getuser().role.slug))
      },
      showRefreshUI (e) {
        // Display a button inviting the user to refresh/reload the app due
        // to an app update being available.
        // The new service worker is installed, but not yet active.
        // Store the ServiceWorkerRegistration instance for later use.
        this.registration = e.detail
        this.updateExists = true
      },
      refreshApp () {
        // Handle a user tap on the update app button.
        this.updateExists = false
        // Protect against missing registration.waiting.
        if (!this.registration || !this.registration.waiting) { return }
        this.registration.waiting.postMessage('skipWaiting')
      },
      refresh () {
        location.reload()
      },
    },
  }
</script>

<style>
  .v-input {
    font-size: 19px !important;
  }
  .v-label {
    font-size: 19px !important;
  }
  .v-btn.v-size--x-large {
    min-height: 56px;
  }
  .v-input .v-text-field__details {
    display: none;
  }
  .w-100 {
    width: 100% !important;
  }
  /*.v-data-table .v-data-table-header tr th {
    font-size: large;
  }
  .v-data-table .v-data-table-header tr th i.v-icon {
    position: absolute;
  }*/
</style>
