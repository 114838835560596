import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

const ifNotAuthenticated = (to, from, next) => {
  if (!localStorage.getItem('token')) {
    next()
    return
  }
  next('/statement-list')
}

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem('token')) {
    next()
    return
  }
  next('/')
}

const logOut = (to, from, next) => {
  // axios.post('logout/' + localStorage.getItem('token'))
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  next('/')
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login,
    beforeEnter: logOut
  },
  {
    path: '/statement-list',
    name: 'statement-list',
    component: () => import('../views/Master/StatementList.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/statement/:id',
    name: 'statement',
    component: () => import('../views/Master/Statement.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/part-list',
    name: 'part-list',
    component: () => import('../views/Master/PartList.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/user-list',
    name: 'user-list',
    component: () => import('../views/Master/UserList.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/equipment-list',
    name: 'equipment-list',
    component: () => import('../views/Master/EquipmentList.vue'),
    beforeEnter: ifAuthenticated
  },
]

const router = new VueRouter({
  routes
})

export default router
