import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

import ru from 'vuetify/es5/locale/ru'

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
  theme: {
    themes: {
      light: {
        primary: colors.grey.darken3,
      },
      dark: {
        primary: colors.grey.darken3,
      }
    },
  },
});
